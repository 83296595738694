.font_size_hover {
  will-change: font-size;
  transition: font-size .25s ease-in-out;
}

.font_size_hover:hover {
  font-size: 110%;
}

.scale_hover {
  will-change: transform;
  transition: transform .25s ease-in-out;
}

.scale_hover:hover {
  transform: scale(1.05);
}
/*# sourceMappingURL=index.740fdf00.css.map */
